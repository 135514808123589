import { useEffect } from "react";

// This is needed to avoid a race condition between the application.html.erb initialization
// and the react components initialization.
// https://getbootstrap.com/docs/4.0/components/tooltips/#example-enable-tooltips-everywhere
const useTooltips = () => {
    useEffect(() => {
        const $ = (window as any).jQuery;

        if (!$) return;

        $('[data-toggle="tooltip"]').tooltip();
    }, []);
}

export default useTooltips;