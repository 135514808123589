import {
  AccessInfoFormState,
  Action,
  StateDispatchType,
  initialStateAppEnabledLockbox,
  initialStateCombinationLockbox,
  initialStateCommunityAccess,
  initialStateDoorCode,
  initialStateHiddenKey,
  initialStateMeetContactOnsite,
  initialStateShowingTime,
} from './types';

export const createInitialState = () : AccessInfoFormState => ({
  combinationLockbox: initialStateCombinationLockbox,
  appEnabledLockbox: initialStateAppEnabledLockbox,
  meetContactOnsite: initialStateMeetContactOnsite,
  hiddenKey: initialStateHiddenKey,
  communityAccess: initialStateCommunityAccess,
  doorCode: initialStateDoorCode,
  showingTime: initialStateShowingTime,
});

export const reducer = (state: AccessInfoFormState, action: StateDispatchType): AccessInfoFormState => {
  switch (action.type) {
    case Action.Set:
      return { ...state, [action.key as string]: action.value };
    case Action.SetMulti:
      return { ...state, ...action.value };
    case Action.Reset:
      return createInitialState();
    default:
      return state;
  }
};
