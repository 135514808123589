export enum DateFormat {
  DateDay = 'dd eee',
  DateMonthYear = 'dd MMMM yyyy',
  DayMonthDate = 'EEEE, MMM d',
  MonthYear = 'MMMM yyyy',
  Time = 'hh:mm a',
}

import { Event } from "react-big-calendar";

export interface CustomEvent extends Event {
  requiresAnnotation?: boolean;
  minimumAvailabilityDuration?: number;
  isSelected?: boolean;
}

export type ExistingAvailability = {
  start: number,
  end: number,
};
