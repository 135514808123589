import React, {ChangeEvent, MouseEventHandler, useEffect, useState} from "react";
import { usePlacesWidget } from 'react-google-autocomplete';
import './styles.scss';
import PropManagementSVG from '../../../assets/images/prop management.svg';
import EmptyLandSVG from '../../../assets/images/land-vacant.svg';
import SingleFamilySVG from '../../../assets/images/single family.svg';
import MinusOutlined from '../../../assets/images/minus_outlined.svg';
import PlusOutlined from '../../../assets/images/plus_outlined.svg';
import useTooltips from "../../hooks/useTooltips";

interface PropertyDetail {
    id: number,
    address: string,
    street2: string
}

interface OrderUnit {
    id: string | null,
    name: string,
    occupancyInfo: string | null,
}

interface Props {
    showMultiUnit: boolean,
    collectOccupancyInfo: boolean,
    showLot: boolean,
    propertyDetail: PropertyDetail,
    orderUnits: OrderUnit[],
    apiKey?: string,
}

type UnitType = 'single_unit' | 'multi_unit' | 'lot';

const AddressUnits: React.FC<Props> = ({showMultiUnit, collectOccupancyInfo, showLot, propertyDetail, orderUnits: initialOrderUnits, apiKey}) => {
    const [unitType, setUnitType] = useState<UnitType | null>(showMultiUnit && initialOrderUnits.length > 0 ? 'multi_unit' : (propertyDetail.id ? 'single_unit' : null));
    const [address, setAddress] = useState(propertyDetail.address || '')
    const [street2, setStreet2] = useState(propertyDetail.street2 || '')
    const [orderUnits, setOrderUnits] = useState(initialOrderUnits.length > 0 ? initialOrderUnits : [{ name: '', id: null, occupancyInfo: null}, { name: '', id: null, occupancyInfo: null}]);
    const [deletedOrderUnitIds, setDeletedOrderUnitIds] = useState<string[]>([]);
    const [errors, setErrors] = useState<any>({});

    useTooltips();

    useEffect(() => {
        const submitButton = document.getElementById('btn-submit-address') as HTMLButtonElement;

        if (unitType && Object.keys(errors).length === 0) {
            submitButton.disabled = false;
        } else {
            submitButton.disabled = true;
        }
    }, [unitType, errors])

    const { ref: mapRef } = usePlacesWidget({
        apiKey: apiKey,
        onPlaceSelected: (place: any) => {
            setAddress(place.formatted_address);
        },
        options: {
            types: ['geocode'],
            componentRestrictions: {country: ['us', 'ca']}
        },
    });

    const unitTypeOnChange = (newUnitType: UnitType) => {
        setUnitType(newUnitType);
        if (newUnitType == 'multi_unit') {
            updateOrderUnitName(street2, 0);
        } else {
            setStreet2(orderUnits[0].name)
        }
    }

    const updateOrderUnitName = (name, index) => {
        if (name && orderUnits.map(orderUnit => orderUnit.name).includes(name)) {
            setErrors({...errors, [index]: {unitName: true}});
        } else {
            let newErrors = {...errors};
            delete newErrors[index];
            setErrors(newErrors);
        }

        let newOrderUnits = [...orderUnits];
        newOrderUnits[index] = {...orderUnits[index], name};
        setOrderUnits(newOrderUnits);
    }

    const updateOrderUnitOccupancyInfo = (occupancyInfo, index) => {
        let newOrderUnits = [...orderUnits];
        newOrderUnits[index] = {...orderUnits[index], occupancyInfo};
        setOrderUnits(newOrderUnits);
    }

    const removeOrderUnit = (index) => {
        if (orderUnits[index].id) {
            setDeletedOrderUnitIds([...deletedOrderUnitIds, orderUnits[index].id]);
        }
        let newOrderUnits = [...orderUnits];
        newOrderUnits.splice(index, 1);
        setOrderUnits(newOrderUnits);
    }

    const addOrderUnit = () => {
        setOrderUnits([...orderUnits, {id: null, name: '', occupancyInfo: null}])
    }

    return (
        <>
            {(showMultiUnit || showLot) && (
                <div className="form-group">
                    {showMultiUnit && (
                        <label>
                            How many units need inspections?
                        </label>
                    )}
                    <div className="row">
                        <div data-toggle="tooltip" title="One unit will be inspected at this address." className="col-12 col-lg-6 mb-3">
                            <input className="box_checkbox_radio_input"
                                   name="order[property_attributes][unit_type]"
                                   type="radio"
                                   value="single_unit"
                                   required={true}
                                   checked={unitType === 'single_unit'}
                                   onChange={(e) => {
                                       if (e.target.checked) {
                                           unitTypeOnChange('single_unit')
                                       }
                                   }}/>
                            <label className="box_checkbox_radio_label m-0" onClick={() => {
                                unitTypeOnChange('single_unit')
                            }}>
                                <div className="box_checkbox_radio_label_content">
                                    <span className="unit_type_icon"><SingleFamilySVG/></span>
                                    Single unit
                                </div>
                            </label>
                            <div className="invalid-feedback py-1">
                                Please select a property type.
                            </div>
                        </div>
                        {showMultiUnit && (
                            <div data-toggle="tooltip" title="Supports units under the same roof with the same address." className="col-12 col-lg-6">
                                <input className="box_checkbox_radio_input unit_type_input"
                                       name="order[property_attributes][unit_type]"
                                       type="radio"
                                       value="multi_unit"
                                       required={true}
                                       checked={unitType === 'multi_unit'}
                                       onChange={(e) => {
                                           if (e.target.checked) {
                                               unitTypeOnChange('multi_unit')
                                           }
                                       }}/>
                                <label className="box_checkbox_radio_label m-0" onClick={() => {
                                    unitTypeOnChange('multi_unit')
                                }}>
                                    <div className="box_checkbox_radio_label_content">
                                        <span className="unit_type_icon"><PropManagementSVG/></span>
                                        Multiple units
                                    </div>
                                </label>
                            </div>
                        )}
                        {showLot && (
                          <div className="col-12 col-lg-6">
                            <input className="box_checkbox_radio_input"
                                   name="order[property_attributes][unit_type]"
                                   type="radio"
                                   value="lot"
                                   required={true}
                                   checked={unitType === 'lot'}
                                   onChange={(e) => {
                                       if (e.target.checked) {
                                           unitTypeOnChange('lot')
                                       }
                                   }}/>
                            <label className="box_checkbox_radio_label m-0" onClick={() => {
                                unitTypeOnChange('lot')
                            }}>
                                <div className="box_checkbox_radio_label_content">
                                    <span className="unit_type_icon"><EmptyLandSVG/></span>
                                    Lot
                                </div>
                            </label>
                          </div>
                      )}
                    </div>
                </div>
            )}
            { /* Conditionally rendering the address field causes problems with the places hook.*/ }
            {unitType !== 'lot' && (
                <div className={`form-group ${ unitType === null ? "invisible" : "" }`}>
                    <label htmlFor="property_address_input">
                        Address
                    </label>
                    <input className="form-control"
                           placeholder=""
                           id="property_address_input"
                           ref={mapRef}
                           required={true}
                           type="text"
                           value={address}
                           name="order[property_attributes][address]"
                           onChange={(e) => setAddress(e.target.value)}/>
                </div>
            )}

            {unitType === 'single_unit' && (
                <div className="form-group street_2_form_group">
                    <label htmlFor="order_property_attributes_street_2">
                        Unit / Apt / Suite (optional)
                    </label>
                    <input className="form-control"
                           type="text"
                           id="order_property_attributes_street_2"
                           name="order[property_attributes][street_2]"
                           value={street2}
                           onChange={(e) => setStreet2(e.target.value)}/>
                </div>
            )}

            {unitType === 'multi_unit' && (
                <>
                    <input type="hidden" value={'multi_family'} name="order[property_attributes][home_type]" />

                    {orderUnits.map((orderUnit, index) => (
                        <div key={index.toString()} className="mb-3">
                            <div className="unit_apt_suite_row">
                                <div className="form-group order_unit_form_group m-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <label className="m-0" htmlFor={`order_order_units_attributes_${index}_name`}>Unit / Apt /
                                            Suite</label>

                                        {collectOccupancyInfo && (

                                            <div className="d-flex position-relative align-items-center">
                                                <input className="form-control mb-0 mx-2 p-0 h-0"
                                                    type="radio"
                                                    name={`order[order_units_attributes][${index}][occupancy_info]`}
                                                    value="occupied"
                                                    checked={orderUnit.occupancyInfo === 'occupied'}
                                                    onChange={(e) => updateOrderUnitOccupancyInfo(e.target.value, index)}
                                                    id={`order_order_units_attributes_${index}_occupancy_info_occupied`}
                                                    required={true} />
                                                <label className="m-0" htmlFor="radio1">Occupied</label>

                                                <input className="form-control mb-0 mx-2 p-0 h-0"
                                                    type="radio"
                                                    name={`order[order_units_attributes][${index}][occupancy_info]`}
                                                    value="vacant"
                                                    checked={orderUnit.occupancyInfo === 'vacant'}
                                                    onChange={(e) => updateOrderUnitOccupancyInfo(e.target.value, index)}
                                                    id={`order_order_units_attributes_${index}_occupancy_info_vacant`}
                                                    required={true} />
                                                <label className="m-0" htmlFor="radio2">Vacant</label>
                                            </div>
                                        )}
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <input className="form-control m-0"
                                            autoComplete="off"
                                            required={true}
                                            type="text"
                                            value={orderUnit.name}
                                            onChange={(e) => {
                                                updateOrderUnitName(e.target.value, index)
                                            }}
                                            name={`order[order_units_attributes][${index}][name]`}
                                            id={`order_order_units_attributes_${index}_name`} />
                                        <button className="link_as_button" type="button" hidden={index < 2} onClick={() => removeOrderUnit(index)}>
                                            <span className="remove_unit_minus_icon"><MinusOutlined /></span>
                                        </button>
                                    </div>
                                    {(errors?.[index]?.unitName === true) && (
                                        <div className="invalid-feedback d-block py-1 fs-6">
                                            The unit has already been added. Please enter a different unit.
                                        </div>
                                    )}
                                </div>
                            </div>
                            {collectOccupancyInfo && (
                                <div className="d-flex">
                                    <input className="d-none"
                                        type="radio"
                                        name={`order[order_units_attributes][${index}][occupancy_info]`}
                                        value=""
                                        id={`order_order_units_attributes_${index}_occupancy_info_validation`}
                                        required={true} />
                                    <div className="invalid-feedback py-1">
                                        Please select occupancy status options.
                                    </div>
                                </div>
                            )}
                            {orderUnit.id && (
                                <input type="hidden"
                                    value={orderUnit.id}
                                    name={`order[order_units_attributes][${index}][id]`} />
                            )}
                        </div>
                    ))}

                    <div className="float-left mb-5">
                        <button type="button" className="link_as_button font-weight-bold" onClick={addOrderUnit}>
                            <span className="add_unit_plus_icon"><PlusOutlined/></span>
                            Add unit
                        </button>
                    </div>
                </>
            )
            }

            {unitType === 'lot' && (
              <>
                  <div className="form-group">
                      <label htmlFor="order_property_attributes_address">Address (Optional)</label>
                      <input className="form-control" type="text" id="order_property_attributes_address"
                             name="order[property_attributes][address]"/>
                  </div>
                  <div className="form-group">
                      <label htmlFor="order_community_name">Project name (Optional)</label>
                      <input className="form-control" type="text" id="order_community_name"
                             name="order[community_name]"/>
                  </div>
                  <div className="form-group">
                      <label htmlFor="order_property_attributes_latitude">Latitude</label>
                      <input className="form-control" type="number" id="order_property_attributes_latitude" min="-90"
                             max="90" step="0.00000000000001"
                             required={true} name="order[property_attributes][latitude]"/>
                  </div>
                  <div className="form-group">
                      <label htmlFor="order_property_attributes_longitude">Longitude (Should be negative for the
                          USA)</label>
                      <input className="form-control" type="number" id="order_property_attributes_longitude" min="-180"
                             max="180" step="0.00000000000001"
                             required={true} name="order[property_attributes][longitude]"/>
                  </div>
              </>
            )}

            { /* Create destroy form entry for all Ids being deleted, and also for all orderUnits if there
                  was orderUnits before and now the user switched back to Single Unit */}
            {[
                ...deletedOrderUnitIds,
                ...(unitType === 'single_unit' ? orderUnits.map((orderUnit) => orderUnit.id).filter(n => n) : [])
            ].map((deletedOrderUnitId, index) => (
                <div key={index.toString()}>
                    <input type="hidden"
                           value={deletedOrderUnitId}
                           name={`order[order_units_attributes][${index + (unitType === 'multi_unit' ? orderUnits.length : 0)}][id]`}/>
                    <input type="hidden"
                           value={1}
                           name={`order[order_units_attributes][${index + (unitType === 'multi_unit' ? orderUnits.length : 0)}][_destroy]`}/>
                </div>
            ))}
        </>
    )
};

export default AddressUnits;
