import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { differenceInMinutes, format } from "date-fns";
import './styles.scss';
import { CustomEvent, DateFormat } from './types';
import TrashSVG from '../../../assets/images/trash.svg';
import { getDurationInHoursFromMinutes, getEarliestEligibleDate, isDateEligible } from "../../utils/time";

interface Props {
  /**
   * The list of timeslots when the property is available
   */
  selectedAvailabilities: CustomEvent[];
  /**
   * The minimum inspection duration in minutes
   */
  minimumAvailabilityDuration: number;
  /**
   * ISO formatted date string for the last day inspections can be scheduled
   */
  inspectionSchedulingDeadline?: string,
  /**
   * Displays the calendar in mobile mode
   */
  isMobile: boolean;
  /**
   * Prevents deleting and adds a click to copy button
   */
  readOnly: boolean;
  /**
   * The date and time in the user's local timezine
   */
  localToday: Date;
  /**
   * Deletes the availability from the calendar
   * @param availability The availability to delete
   * @returns 
   */
  handleDeleteAvailability: (availability: CustomEvent) => void;
}

const DatesSelectedPanel: React.FC<Props> = ({
  selectedAvailabilities,
  minimumAvailabilityDuration,
  isMobile,
  readOnly,
  localToday,
  inspectionSchedulingDeadline,
  handleDeleteAvailability
}) => {
  const [textCopied, setTextCopied] = React.useState(false);
  const [textCopiedTimeout, setTextCopiedTimeout] = React.useState<NodeJS.Timeout | null>(null);
  const groupedDates = React.useMemo(() => {
    const sortedAvailabilities = selectedAvailabilities.sort((a, b) => a.start.getTime() - b.start.getTime());
    const weeks = {};

    sortedAvailabilities.forEach(availability => {
      const week = format(availability?.start, DateFormat.DayMonthDate);
      if (!weeks[week]) {
        weeks[week] = [availability];
      } else {
        weeks[week].push(availability);
      }
    });
    return weeks;
  }, [selectedAvailabilities]);

  const copyToClipboard = React.useCallback(() => {
    const formattedAvailabilities = selectedAvailabilities.map(availability =>
      `${format(availability.start, 'M/d')} between ${format(availability.start, 'h:mma')}-${format(availability.end, 'h:mma')}`
    ).join(', ');
    navigator.clipboard.writeText(formattedAvailabilities);
    setTextCopied(true);
    if (textCopiedTimeout) {
      clearTimeout(textCopiedTimeout)
    }
    setTextCopiedTimeout(setTimeout(() => setTextCopied(false), 3000));
  }, [selectedAvailabilities, textCopiedTimeout, setTextCopiedTimeout]);

  const requiredAvailabilityInHours = () : string => {
    const requiredAvailabilityInMin = minimumAvailabilityDuration * 2;
    return getDurationInHoursFromMinutes(requiredAvailabilityInMin);
  }

  const selectedAvailabilityInHours = () : string => {
    const earliestEligibleDate: Date = getEarliestEligibleDate(localToday, inspectionSchedulingDeadline);
    let selectedAvailabilitiesInMin =  0;
    for (let i = 0; i < selectedAvailabilities.length; i++) {
      if (selectedAvailabilities[i].start > earliestEligibleDate) {
        const availabilityDuration = differenceInMinutes(selectedAvailabilities[i].end, selectedAvailabilities[i].start);
        selectedAvailabilitiesInMin += availabilityDuration;
      }
    }
    return getDurationInHoursFromMinutes(selectedAvailabilitiesInMin);
  }

  return (
    <div className={isMobile ? "datesSelectedContainerMobile" : "datesSelectedContainer"}>
      <div className="datesSelectedHeader">
        <div className="datesSelectedHeaderText">{readOnly ? 'DATES REQUESTED' : 'DATES SELECTED'}</div>
        {readOnly && selectedAvailabilities.length > 0 && (
          <div title="Copy availabilities to clipboard" className="datesSelectedCopy" onClick={copyToClipboard}>
            {
              textCopied ? (
                <div className="datesSelectedCopied">
                  <div className="datesSelectedCopiedIcon"><FontAwesomeIcon icon={faCheck} /></div>
                  Copied
                </div>
              ) : (
                <FontAwesomeIcon icon={faCopy} />
              )
            }
          </div>
        )}
      </div>

      {selectedAvailabilities.length === 0 && (
        <div className="noDatesSelected">No dates selected</div>
      )}
      <div className="selectedHoursCountText"> {`${selectedAvailabilityInHours()} of ${requiredAvailabilityInHours()} selected`} </div>
      {
        Object.keys(groupedDates).map((week, index) => (
          <div key={index}>
            <div className={isDateEligible(localToday, groupedDates[week][0].start, inspectionSchedulingDeadline) ? "datesSelectedDateText--eligible" : "datesSelectedDateText--ineligible" }>{week}</div>
            {groupedDates[week].map(selectedAvailability => (
              <div
                key={selectedAvailability.start.getTime()}
                className={isDateEligible(localToday, groupedDates[week][0].start, inspectionSchedulingDeadline) ? "datesSelectedTimeText--eligible" : "datesSelectedTimeText--ineligible"}
              >
                {format(selectedAvailability?.start, 'h:mm a') + ' - ' + format(selectedAvailability?.end, 'h:mm a')}
                <button className="deleteAvailabilityButtonPanel" onClick={() => handleDeleteAvailability(selectedAvailability)}>
                  <TrashSVG />
                </button>
              </div>
            ))}
            {!isDateEligible(localToday, groupedDates[week][0].start, inspectionSchedulingDeadline) && (
              <div className="datesSelectedWarningText">
                While feasible, we cannot guarantee an inspection on this date.
              </div>
            )}
          </div>
        ))}
    </div>
  );
}

export default DatesSelectedPanel;
