import React, {useMemo, useState} from "react";
import './styles.scss';
import colors from './colors.json';
import {AdvancedMarker, InfoWindow, Pin, useAdvancedMarkerRef} from "@vis.gl/react-google-maps";

interface Props {
  address: string,
  lat: number,
  lng: number,
  inspectionID: string,
  inspectionType: string,
  bundleKey?: number,
}

const InspectionPin: React.FC<Props> = ({
  address,
  lat,
  lng,
  inspectionID,
  inspectionType,
  bundleKey,
}) => {
  const [markerRef, marker] = useAdvancedMarkerRef();
  const [showBubble, setShowBubble] = useState(false);
  const fillColor = useMemo(() => colors[(bundleKey || 0) % colors.length], [bundleKey]);
  return (
    <AdvancedMarker
      ref={markerRef}
      position={{lat: lat, lng: lng}}
      onClick={() => setShowBubble(!showBubble)} className="inspectionPin">
      <Pin background={fillColor} borderColor={'black'} glyphColor={'black'} />
      {showBubble &&
        <InfoWindow anchor={marker} onCloseClick={() => setShowBubble(false)}>
          <b>{address}</b>
          <div>Inspection ID: {inspectionID}</div>
          <div>Type: {inspectionType}</div>
          {bundleKey && (
            <div>Bundle #{bundleKey}</div>
          )}
        </InfoWindow>
      }
    </AdvancedMarker>
  )
};

export default InspectionPin;
