import React from 'react';

interface CustomToggleProps {
  children: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const IconButton = React.forwardRef(
  (props: CustomToggleProps, ref: React.Ref<HTMLButtonElement>) => (
    <button
      id="icon-button"
      type="button"
      ref={ref}
      style={{
        background: 'none',
        border: 'none',
        cursor: 'pointer',
      }}
      onClick={(e) => {
        props.onClick(e);
      }}
    >
      {props.children}
    </button>
  ),
);

export default IconButton;