import React, {useMemo, useState} from "react";
import './styles.scss';
import {AdvancedMarker, InfoWindow, Pin, useAdvancedMarkerRef} from "@vis.gl/react-google-maps";
import {Order} from "./types";

interface Props {
  order: Order,
  onClick: () => void,
  onClose: () => void,
  infoWindowShown: boolean,
  isOnBundle: boolean,
  isAlreadySubmitted: boolean,
  addOrderToBundle: (order: Order) => void,
  removeOrderFromBundle: (order: Order) => void,
}

const OrderMarker: React.FC<Props> = (
  {order, onClick, onClose, infoWindowShown, isOnBundle, isAlreadySubmitted, addOrderToBundle, removeOrderFromBundle}
) => {
  const [markerRef, marker] = useAdvancedMarkerRef();

  const pinColor = useMemo(() => {
    if (isOnBundle) {
      return 'purple';
    } else if (isAlreadySubmitted) {
      return '#FF5200';
    } else if (order.eligibilityErrors.length > 0) {
      return '#ffc107';
    } else {
      return 'green';
    }
  }, [isOnBundle, order.eligibilityErrors]);

  return (
    <>
      <AdvancedMarker ref={markerRef}
                      position={{lat: parseFloat(order.latitude), lng: parseFloat(order.longitude)}}
                      onClick={onClick}
      >
        <Pin background={pinColor} borderColor={'black'} glyphColor={'black'}/>
      </AdvancedMarker>
      {infoWindowShown && (
        <InfoWindow anchor={marker} onCloseClick={onClose}>
          <b>Order:</b> <a href={`/admin/order/${order.id}/edit`} target="_blank">{order.id}</a>
          <div>{order.teamName} ({order.teamId})</div>
          <div>
            <b>Inspections:</b> {order.inspections.map((inspection) => inspection.inspectionType).join(', ')}
          </div>
          {order.contingencyDate &&
            <div>
              <>
                <b>Contingency Date:</b> {order.contingencyDate}
              </>
            </div>
          }
          {order.isGoAnytime ? (<div><b>Go Anytime</b></div>) : (
            <>
              {order.earliestAvailabilityDate ? (
                <>
                  <div>
                    <>
                      <b>Earliest Availability:</b> {order.earliestAvailabilityDate}
                    </>
                  </div>
                  <div>
                    <>
                      <b>Latest Availability:</b> {order.latestAvailabilityDate}
                    </>
                  </div>
                </>
              ) : (
                <div><b className="text-danger">No Upcoming Availabilities</b></div>
              )}
            </>
          )}
          {order.eligibilityErrors.length === 0 ? (
            <>
              {!isOnBundle && (
                <div>
                  <button className={'btn btn-sm btn-primary'}
                          onClick={() => addOrderToBundle(order)}>Add
                  </button>
                </div>
              )}
            </>
          ) : (
            <div>
              <b>Eligibility Errors:</b>
              <ul>
                {order.eligibilityErrors.map((error, index) => (
                  <li key={index} className="text-danger">{error}</li>
                ))}
              </ul>
            </div>
          )}
          {isAlreadySubmitted && (
            <div>
              <b className="text-danger">Already Submitted</b>
            </div>
          )}
          {isOnBundle && (
            <div>
              <button className={'btn btn-sm btn-danger'} onClick={() => removeOrderFromBundle(order)}>Remove
              </button>
            </div>
          )}
        </InfoWindow>
      )}
    </>
  )
};

export default OrderMarker;
