import { addDays, differenceInDays, getDay, isBefore, startOfDay } from "date-fns";

// Helper function to get the earliest Date of an availability that "counts"
// (i.e., is blue and allows the user to progress in the booking flow)
export const getEarliestEligibleDate = (localToday: Date, inspectionSchedulingDeadline?: string,) : Date => {
  let earliestEligibleDate: Date;
  const todayDate = startOfDay(localToday);
  if (inspectionSchedulingDeadline) {
    const schedulingDeadlineDate = new Date(inspectionSchedulingDeadline);
    if (differenceInDays(schedulingDeadlineDate, todayDate) < 3) {
      earliestEligibleDate = todayDate;
    } else {
      earliestEligibleDate = getDay(addDays(todayDate, 2)) === 0 ? addDays(todayDate, 3) : addDays(todayDate, 2);
    }
  } else {
    earliestEligibleDate = getDay(addDays(todayDate, 2)) === 0 ? addDays(todayDate, 3) : addDays(todayDate, 2);
  }
  return earliestEligibleDate;
};

// Helper function to determine if an availability counts towards total selected availability time
export const isDateEligible = (localToday: Date, availabilityStartTime: Date, inspectionSchedulingDeadline?: string, ) : boolean => {
  const earliestEligibleDate: Date = getEarliestEligibleDate(localToday, inspectionSchedulingDeadline);
  if (isBefore(availabilityStartTime, earliestEligibleDate)) return false;
  return true;
};

// Helper function to convert a number of minutes into the format X hours and Y minutes
export const getDurationInHoursFromMinutes = (durationInMinutes: number) : string => {
  const hours = Math.floor(durationInMinutes / 60);
  const min = durationInMinutes % 60;
  if (min === 0) {
    return `${hours} hr`;
  } else {
    return `${hours} hr ${min} min`;
  } 
};
