import DatesSelectedPanel from './DatesSelectedPanel';
import DateNavigation from './DateNavigation';
import React, { Fragment, HtmlHTMLAttributes, useMemo } from 'react';
import { Calendar, SlotInfo, dateFnsLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { startOfWeek, parse, getDay } from 'date-fns';
import { getTimezoneOffset, zonedTimeToUtc, format } from 'date-fns-tz';
import { enUS } from 'date-fns/locale';
import TrashSVG from '../../../assets/images/trash.svg';
import { CustomEvent } from './types';
import './styles.scss';

const CalendarWithDragAndDrop = withDragAndDrop(Calendar);

interface Props {
  /**
   * The list of timeslots when the property is available
   */
  selectedAvailabilities: CustomEvent[],
  /**
   * The date representing the date/week to display on the calendar
   */
  currentViewableDate: {
    date: Date,
    title: string,
  },
  /**
   * The minimum selectable time of day
   */
  min: Date,
  /**
   * The maximum selectable time of day
   */
  max: Date,
  /**
   * The date and time in the user's local timezine
   */
  localToday: Date,
  /**
   * Displays the calendar in mobile mode
   */
  isMobile: boolean,
  /**
   * The minimum inspection duration in minutes
   */
  minimumAvailabilityDuration: number,
  /**
   * ISO formatted date string for the last day inspections can be scheduled
   */
  inspectionSchedulingDeadline?: string,
  /**
   * The property timezone
   */
  timezone: string,
  /**
   * Prevents editing the availabilities
   */
  readOnly: boolean,
  /**
   * Smaller version of the calendar without the dates panel
   */
  minimized: boolean,
  /**
   * Display the layout vertically
  */
  vertical: boolean,
  /**
   * Only display the date panel
   */
  panelOnly?: boolean,
  /**
   * Handler function when a new availability is created
   * @param eventInfo Info about the new availability
   * @returns 
   */
  handleSelectSlot: (eventInfo: SlotInfo) => void,
  handleEventUpdate: (args: {
    event: CustomEvent,
    start: Date,
    end: Date,
    isAllDay: boolean,
  }) => void,
  /**
   * Changes the selected date to display on the calendar
   * @param newDate The new week date to display
   * @returns 
   */
  handleDateChange: (newDate: Date) => void,
  /**
   * Deletes the availability from the calendar
   * @param availability The availability to delete
   * @returns 
   */
  handleDeleteAvailability: (availability: CustomEvent) => void,
  getProperties: (date: Date) => HtmlHTMLAttributes<HTMLDivElement>,
  getNow: () => Date,
  getEventProperties: (event: CustomEvent) => HtmlHTMLAttributes<HTMLDivElement>;
}

/**
 * Calendar that defines order availabilities
 */
const AvailabilityCalendar: React.FC<Props> = ({
  selectedAvailabilities,
  currentViewableDate,
  min,
  max,
  isMobile,
  minimumAvailabilityDuration,
  inspectionSchedulingDeadline,
  timezone,
  readOnly,
  minimized,
  vertical,
  panelOnly,
  localToday,
  handleSelectSlot,
  handleEventUpdate,
  handleDateChange,
  handleDeleteAvailability,
  getProperties,
  getEventProperties,
  getNow,
}) => {

  const locales = {
    'en-US': enUS,
  }

  let EventComponent = ({
    event
  }) => {
    const minimumTimeString = `${Math.floor(event.minimumAvailabilityDuration / 60)}h${event.minimumAvailabilityDuration % 60 > 0 ? event.minimumAvailabilityDuration % 60 + 'm' : ''}`;
    return (
      <div>
        {event.isSelected && (
          <button className='deleteAvailabilityButton' onClick={() => handleDeleteAvailability(event)}>
            <TrashSVG />
          </button>
        )}
        {event.requiresAnnotation && (
          <div className="minimumTimeslotDurationTooltip">
            Time slot must be at least {minimumTimeString}
          </div>
        )}
      </div>
    )
  };

  const localizer = dateFnsLocalizer({
    format,
    startOfWeek,
    parse,
    getDay,
    locales,
  });

  const timezoneString = useMemo(() => {
    const offset = getTimezoneOffset(timezone, min) / 3600000;
    if (offset > 0) {
      return `GMT+${offset}`;
    } else {
      return `GMT${offset}`
    }
  }, [timezone, min]);

  const formValue = useMemo(() => JSON.stringify(
    selectedAvailabilities
      .map(availability => ({ start: zonedTimeToUtc(availability.start, timezone).getTime() / 1000, end: zonedTimeToUtc(availability.end, timezone).getTime() / 1000 }))
      .sort((a, b) => a.start - b.start)
  ), [selectedAvailabilities]);

  return (
    <Fragment>
      {!readOnly && (
        <input type="hidden" name="order_availabilities" value={formValue} />
      )}
      <div className={`componentContainer ${readOnly ? 'readOnly' : ''} ${minimized ? 'minimized' : ''} ${vertical ? 'vertical' : ''} ${panelOnly ? 'panelOnly' : ''}`}>
        {isMobile ?
          <div className="calendarPanelContainerMobile">
            <DatesSelectedPanel
              selectedAvailabilities={selectedAvailabilities}
              isMobile={isMobile}
              readOnly={readOnly}
              localToday={localToday}
              minimumAvailabilityDuration={minimumAvailabilityDuration}
              inspectionSchedulingDeadline={inspectionSchedulingDeadline}
              handleDeleteAvailability={handleDeleteAvailability}
            />
            {!minimized && (
              <>
                <DateNavigation currentViewableDate={currentViewableDate} isMobile={isMobile} handleDateChange={handleDateChange} />
                <div className="timezoneText">{timezoneString}</div>
                <div className="calendarContainer">
                  <Calendar
                    events={selectedAvailabilities}
                    localizer={localizer}
                    onSelectSlot={handleSelectSlot}
                    selectable={!readOnly}
                    dayLayoutAlgorithm="no-overlap"
                    defaultView="day"
                    views={["day"]}
                    min={min}
                    max={max}
                    toolbar={false}
                    date={currentViewableDate.date}
                    onNavigate={(newDate: Date) => handleDateChange(newDate)}
                    step={15}
                    timeslots={4}
                    longPressThreshold={80}
                    onSelectEvent={(e) => selectedAvailabilities.forEach(av => {
                      av.isSelected = e === av;
                    })}
                    components={
                      { event: EventComponent }
                    }
                    eventPropGetter={getEventProperties}
                    dayPropGetter={getProperties}
                    slotPropGetter={getProperties}
                    getNow={getNow}
                  />
                </div>
              </>
            )}
          </div>
          :
          <>
            {!minimized && !panelOnly && (
              <>
                <DateNavigation currentViewableDate={currentViewableDate} isMobile={isMobile} handleDateChange={handleDateChange} />
                <div className="timezoneText">{timezoneString}</div>
              </>
            )}
            <div className={"calendarPanelContainer"}>
              {!panelOnly && (
                <div className="calendarContainer">
                  {!minimized && (
                    readOnly ? (
                      <Calendar
                        events={selectedAvailabilities}
                        localizer={localizer}
                        dayLayoutAlgorithm="no-overlap"
                        defaultView="week"
                        views={["week"]}
                        min={min}
                        max={max}
                        toolbar={false}
                        onNavigate={(newDate: Date) => handleDateChange(newDate)}
                        step={15}
                        timeslots={4}
                        components={
                          { event: EventComponent }
                        }
                        eventPropGetter={getEventProperties}
                        dayPropGetter={getProperties}
                        slotPropGetter={getProperties}
                        getNow={getNow}
                        date={currentViewableDate.date}
                      />
                    ) : (
                      <CalendarWithDragAndDrop
                        events={selectedAvailabilities}
                        localizer={localizer}
                        selectable
                        dayLayoutAlgorithm="no-overlap"
                        resizable
                        defaultView="week"
                        views={["week"]}
                        min={min}
                        max={max}
                        toolbar={false}
                        onNavigate={(newDate: Date) => handleDateChange(newDate)}
                        step={15}
                        timeslots={4}
                        onEventDrop={handleEventUpdate}
                        onSelectSlot={handleSelectSlot}
                        onEventResize={handleEventUpdate}
                        longPressThreshold={0}
                        onSelectEvent={(e) => selectedAvailabilities.forEach(av => {
                          av.isSelected = e === av;
                        })}
                        components={
                          { event: EventComponent }
                        }
                        eventPropGetter={getEventProperties}
                        dayPropGetter={getProperties}
                        slotPropGetter={getProperties}
                        getNow={getNow}
                        date={currentViewableDate.date}
                      />
                    ))}
                </div>
              )}
              <DatesSelectedPanel
                selectedAvailabilities={selectedAvailabilities}
                isMobile={isMobile}
                readOnly={readOnly}
                localToday={localToday}
                minimumAvailabilityDuration={minimumAvailabilityDuration}
                inspectionSchedulingDeadline={inspectionSchedulingDeadline}
                handleDeleteAvailability={handleDeleteAvailability}
              />
            </div>
          </>
        }
      </div>
    </Fragment>
  )
}

export default AvailabilityCalendar;
