import React, {useCallback, useEffect, useMemo, useState} from "react";
import Dropzone from 'react-dropzone';
import { faUpload } from "@fortawesome/free-solid-svg-icons";

import './styles.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const MultiFilesInput: React.FC = () => {
  const hiddenFileInput = React.useRef(null);
  const [files, setFiles] = useState<File[]>([]);
  const [form, setForm] = useState(null);

  const handleClick = (e: any) => {
    e.preventDefault();
    hiddenFileInput.current.click();
  };

  const handleChange = (newFiles: any) => {
    console.log(newFiles);
    setFiles([...files, ...newFiles]);
  };


  const renderFiles = () => (files as any[]).map((file: any) => {
    const filename = file.name;

    return (
      <div key={filename}>{filename}</div>
    );
  });

  const submitForm = useCallback((e: SubmitEvent) => {
    if (!form) return null;

    e.preventDefault();

    const formData = new FormData(form);

    files.forEach((file) => {
      formData.append(`files[]`, file);
    });

    formData.append('test', 'test');

    console.log(formData);

    fetch(form.action, {
      method: form.method,
      body: formData,
    }).then((response) => {
      if (response.redirected) {
        window.location.href = response.url;
      } else {
        window.alert("There was an error submitting the files. Please try again or contact support.");
      }
    });
  }, [form, files]);

  useEffect(() => {
    setForm(hiddenFileInput.current.form);
  }, [hiddenFileInput]);

  useEffect(() => {
    if (submitForm) {
      addEventListener('submit', submitForm);

      return () => {
        removeEventListener('submit', submitForm);
      }
    }
  }, [submitForm]);

  return (
    <>
      <div className="sharedStyles">
        {renderFiles()}
        <>
          <Dropzone onDrop={(acceptedFiles) => handleChange(acceptedFiles)}>
            {({getRootProps, getInputProps, isDragActive}) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} ref={hiddenFileInput}/>
                <button className="addButton"
                        onClick={handleClick}
                        style={{display: 'flex', alignItems: 'center', justifyContent: 'center',
                          backgroundColor: (isDragActive ? '#00d589' : 'transparent')}}
                >
                  <FontAwesomeIcon icon={faUpload} style={{ marginRight: "10px" }} />
                  {' Drop files here or click to choose files'}
                </button>
              </div>
            )}
          </Dropzone>
        </>
      </div>
    </>
  );
};

export default MultiFilesInput;
