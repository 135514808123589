import React, { useEffect, useMemo, useReducer } from "react";
import './styles.scss';
import {
  AccessInfoFormLoaderProps,
  AccessInformationDBTypes,
  AccessTypeEnum,
  AccessTypeTitleEnum,
  Action,
  AppLockboxBrandEnum,
  InputFieldEnum,
} from "./types";
import AccessInfoForm from "./AccessInfoForm";
import { isUserUsingMobile } from "../../utils/user";
import { createInitialState, reducer } from "./state";


const AccessInfoFormLoader: React.FC<AccessInfoFormLoaderProps> = ({
  mlsOuids, accessInformations,
}) => {
  const minimized = isUserUsingMobile();

  const initialStates = useMemo(() => {
    if (accessInformations.length === 0) {
      return createInitialState();
    } else {
      let accessInfoState = createInitialState();

      accessInformations.forEach((accessInformation) => {
        switch (accessInformation.type) {
          case AccessInformationDBTypes.appEnabledLockbox:
            accessInfoState[AccessTypeEnum.appEnabledLockbox] = {
              ...accessInfoState.appEnabledLockbox, 
              brand: accessInformation.lockbox_brand,
              directions: accessInformation.directions,
              isSelected: true,
            }
            break;
          case AccessInformationDBTypes.combinationLockbox:
            accessInfoState[AccessTypeEnum.combinationLockbox] = {
              ...accessInfoState.combinationLockbox,
              code: accessInformation.code,
              directions: accessInformation.directions,
              isSelected: true,
            }
            break;
          case AccessInformationDBTypes.communityAccess:
            accessInfoState[AccessTypeEnum.communityAccess] = {
              ...accessInfoState.communityAccess,
              code: accessInformation.code,
              directions: accessInformation.directions,
              isSelected: true,
            }
            break;
          case AccessInformationDBTypes.doorCode:
            accessInfoState[AccessTypeEnum.doorCode] = {
              ...accessInfoState.doorCode,
              code: accessInformation.code,
              directions: accessInformation.directions,
              isSelected: true,
            }
            break;
          case AccessInformationDBTypes.hiddenKey:
            accessInfoState[AccessTypeEnum.hiddenKey] = {
              ...accessInfoState.hiddenKey,
              directions: accessInformation.directions,
              isSelected: true,
            }
            break;
          case AccessInformationDBTypes.meetContactOnsite:
            accessInfoState[AccessTypeEnum.meetContactOnsite] = {
              ...accessInfoState.meetContactOnsite,
              fullName: accessInformation.contact_name,
              phoneNumber: accessInformation.contact_phone_number?.slice(0, 14),
              extension: accessInformation.contact_phone_number?.includes('ext') ? accessInformation.contact_phone_number.split('ext ')[1] : '',
              email: accessInformation.contact_email,
              isSelected: true,
            }
            break;
          case AccessInformationDBTypes.showingTime:
            accessInfoState[AccessTypeEnum.showingTime] = {
              ...accessInfoState.showingTime,
              mlsOuid: accessInformation.mls_name,
              mlsId: accessInformation.mls_id,
              isSelected: true,
            }
            break;
          case AccessInformationDBTypes.general:
            accessInfoState[AccessTypeEnum.general] = {
              type: AccessTypeEnum.general,
              title: AccessTypeTitleEnum.general,
              inputFields: [InputFieldEnum.directions],
              showFullDirectionsError: false,
              directions: accessInformation.directions,
              isSelected: true,
            }
          default:
            return accessInfoState;
        }
      });
      return accessInfoState;
    }
  }, [accessInformations]);

  const [state, updateState] = useReducer(reducer, initialStates)
  const setState = (key: AccessTypeEnum, value: any) => updateState({ type: Action.Set, key, value });

  const selectedAccessTypes = useMemo(() => Object.values(state).filter((accessType) => accessType.isSelected), [state]);

  const enableNextButton: boolean = useMemo(() => {
    let communityAccessIsComplete: boolean = false;
    let completedAccessTypesCount: number = 0;
    let selectedAccessTypesCount: number = selectedAccessTypes?.length;
    
    if (selectedAccessTypes?.length > 0) {
      selectedAccessTypes.forEach((accessType: any) => {
        switch (accessType.type) {
          case AccessTypeEnum.appEnabledLockbox:
            if (accessType.brand?.length > 0 && accessType.directions?.length > 0) {
              completedAccessTypesCount++;
              return;
            }
          case AccessTypeEnum.combinationLockbox:
            if (accessType.code?.length > 0 && accessType.directions?.length > 0) {
              completedAccessTypesCount++;
              return;
            }
          case AccessTypeEnum.doorCode:
            if (accessType.code?.length > 0 && accessType.directions?.length > 0) {
              completedAccessTypesCount++;
              return;
            }
          case AccessTypeEnum.communityAccess:
            if (accessType.code?.length > 0 || accessType.directions?.length > 0) {
              communityAccessIsComplete = true;
              return;
            }
          case AccessTypeEnum.hiddenKey:
            if (accessType.directions?.length > 0) {
              completedAccessTypesCount++;
              return;
            }
          case AccessTypeEnum.meetContactOnsite:
            if (accessType.fullName?.length > 0 && accessType.phoneNumber?.length >= 10) {
              completedAccessTypesCount++;
              return;
            }
          case AccessTypeEnum.showingTime:
            if (accessType.mlsOuid?.length > 0) {
              completedAccessTypesCount++;
              return;
            }
          case AccessTypeEnum.general:
            if (accessType.directions?.length > 0) {
              completedAccessTypesCount++;
              return;
            }
          default: return;
        }
      })
      
      // Handle community access edge cases
      if (selectedAccessTypes.some((accessType) => accessType.type === AccessTypeEnum.communityAccess)
        && communityAccessIsComplete
        && completedAccessTypesCount === selectedAccessTypesCount - 1
      ) return true;  

      if (completedAccessTypesCount > 0 && completedAccessTypesCount === selectedAccessTypesCount) return true;
    }
    return false;
  }, [selectedAccessTypes]);

  const handleSelectAccessType = (accessType: any) => (
    setState(accessType.type, { ...accessType, isSelected: !accessType.isSelected })
  );

  const handleShowErrorMessage = (accessType: any) => {
    if (!accessType.isSelected) {
     return;
    }
    const newAccessTypeState = {...accessType};
    switch (accessType.type) {
      case AccessTypeEnum.appEnabledLockbox:
        if (!Object.values(AppLockboxBrandEnum).includes(accessType.brand)) {
          newAccessTypeState.showBrandError = true;
        }
        if (accessType.directions?.length < 1) {
          newAccessTypeState.showDirectionsError = true;
        }
        break;
      case AccessTypeEnum.combinationLockbox:
        if (accessType.code?.length < 1) {
          newAccessTypeState.showCodeError = true;
        }
        if (accessType.directions?.length < 1) {
          newAccessTypeState.showDirectionsError = true;
        }
        break;
      // Community Access requires either a code or directions (or can have both, but both are not required)
      case AccessTypeEnum.communityAccess:
        if (accessType.code?.length < 1 && accessType.directions?.length < 1) {
          newAccessTypeState.showCodeError = true;
          newAccessTypeState.showDirectionsError = true;
          break;
        }
        if (accessType.code?.length > 0 || accessType.directions?.length > 0) {
          newAccessTypeState.showCodeError = false;
          newAccessTypeState.showDirectionsError = false;
          break;
        }
      case AccessTypeEnum.doorCode:
        if (accessType.code?.length < 1) {
          newAccessTypeState.showCodeError = true;
        }
        if (accessType.directions?.length < 1) {
          newAccessTypeState.showDirectionsError = true;
        }
        break;
      case AccessTypeEnum.hiddenKey:
        if (accessType.directions?.length < 1) {
          newAccessTypeState.showDirectionsError = true;
        }
        break;
      case AccessTypeEnum.meetContactOnsite:
        if (accessType.fullName?.length < 1) {
          newAccessTypeState.showFullNameError = true;
        }
        if (accessType.phoneNumber?.length < 10) {
          newAccessTypeState.showPhoneNumberError = true;
        }
        if (accessType.email?.length > 0 && !/\S+@\S+\.\S+/.test(accessType.email)) {
          newAccessTypeState.showEmailError = true;
        }
        break;
      case AccessTypeEnum.showingTime:
        if (!Object.values(mlsOuids).includes(accessType.mlsOuid)) {
          newAccessTypeState.showMlsOuidError
        }
        break;
      case AccessTypeEnum.general:
        if (accessType.directions?.length < 1) {
          newAccessTypeState.showDirectionsError = true;
        }
        break;
      default: break;
    }
    setState(accessType.type, newAccessTypeState);
  };

  useEffect(() => {
    if (enableNextButton) {
      var event = new Event("access_information_complete");
      document.dispatchEvent(event);
    } else {
      var event = new Event("access_information_incomplete");
      document.dispatchEvent(event);
    }
  }, [enableNextButton]);

  return (
   <AccessInfoForm
    minimized={minimized}
    mlsOuids={mlsOuids}
    state={state}
    setState={setState}
    handleShowErrorMessage={handleShowErrorMessage}
    handleSelectAccessType={handleSelectAccessType}
   />
  );
}

export default AccessInfoFormLoader;
