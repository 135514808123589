import * as React from "react";
import InspectionPin from './InspectionPin';
import './styles.scss';
import {APIProvider, Map, useMap} from "@vis.gl/react-google-maps";
import {useEffect} from "react";

interface InspectionMapDetail {
  latitude: number,
  longitude: number,
  address: string,
  inspectionType: string,
  id: string,
  bundleKey?: number,
}

interface Props {
  inspections?: InspectionMapDetail[],
  mapId?: string,
}

const DEFAULT_USA_COORDS = {
  lat: 39.8097343, lng: -98.5556199
};

const InspectionsMap: React.FC<Props> = ({inspections, mapId}) => {
  const map = useMap('inspections-map');

  useEffect(() => {
    if (map) {
      if (new Set(inspections.map((inspection) => inspection.address)).size > 1) {
        // Use inspection coordinates to fit map to bounds
        let bounds = new google.maps.LatLngBounds();
        inspections.forEach(inspection => {
          bounds.extend(new google.maps.LatLng(inspection.latitude, inspection.longitude));

        })
        map.fitBounds(bounds);
      } else {
        map.setCenter({lat: inspections[0].latitude, lng: inspections[0].longitude})
        map.setZoom(8);
      }
    }
  }, [map]);

  return (
    <div className="mapContainer">
        <Map
          id={'inspections-map'}
          defaultCenter={DEFAULT_USA_COORDS}
          defaultZoom={4}
          gestureHandling={'greedy'}
          disableDefaultUI={true}
          mapId={mapId}
        >
          {inspections.map((inspection, index) => (
            <InspectionPin
              lat={inspection.latitude}
              lng={inspection.longitude}
              address={inspection.address}
              inspectionID={inspection.id}
              inspectionType={inspection.inspectionType}
              key={`inspection-pin-${index}`}
              bundleKey={inspection.bundleKey}
            />
          ))}
        </Map>
    </div>
  );
};

export default InspectionsMap;
