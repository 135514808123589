/*
  Accepts any string input and removes any non-valid phone digits
  Returns only the first 10 integers as a string
*/
export const unformatPhoneNumber = (phoneNumber: string) => phoneNumber.replace(/\D/g, '').substring(0, 10);

/*
  Accepts any string input and removes any non-valid phone digits
  Returns the phone number input formatted as (XXX) XXX-XXXX
*/
export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return phoneNumber;
  const digitsOnly = unformatPhoneNumber(phoneNumber);

  if (digitsOnly.length < 4) return digitsOnly;
  if (digitsOnly.length < 7) {
    return `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3)}`;
  }

  return `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6, 10)}`;
};