import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { format, endOfDay, subDays, differenceInMinutes, addMinutes, subMinutes, addDays, roundToNearestMinutes, fromUnixTime, addYears, parseISO, startOfWeek } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { enUS } from 'date-fns/locale';
import { SlotInfo } from 'react-big-calendar';
import AvailabilityCalendar from './AvailabilityCalendar';
import { CustomEvent, DateFormat, ExistingAvailability } from './types';
import { getEarliestEligibleDate, isDateEligible } from '../../utils/time';

interface Props {
  /**
   * The minimum inspection duration in minutes
   */
  minimumAvailabilityDuration: number,
  /**
   * The lower hour boundary for availabilities
   */
  minHour: number,
  /**
   * The upper hour boundary for availabilities
   */
  maxHour: number,
  /**
   * The property timezone
   */
  timezone?: string,
  /**
   * Specific dates that cannot be selected by the user (e.g. holidays)
   */
  blockedDates: number[],
  /**
   * List of existing availabilities to load
   */
  existingAvailabilities?: ExistingAvailability[],
  /**
   * Prevents editing the availabilities
   */
  readOnly: boolean,
  /**
   * Smaller version without the dates panel
   */
  minimized: boolean,
  /**
   * ISO formatted date string for the last day inspections can be scheduled
   */
  inspectionSchedulingDeadline?: string,
  /**
   * Display the layout vertically
   */
  vertical?: boolean,
  /**
   * Only display the date panel
   */
  panelOnly?: boolean,
}

const AvailabilityCalendarLoader: React.FC<Props> = ({
  minimumAvailabilityDuration: propsMinimumAvailabilityDuration,
  timezone,
  blockedDates,
  existingAvailabilities,
  readOnly,
  minimized,
  inspectionSchedulingDeadline: propsSchedulingDeadline,
  minHour,
  maxHour,
  vertical,
  panelOnly,
}) => {
  try {
    Intl.DateTimeFormat(undefined, { timeZone: timezone });
  } catch {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  const [inspectionSchedulingDeadline, setInspectionSchedulingDeadline] = useState<string>(propsSchedulingDeadline);
  const [minimumAvailabilityDuration, setMinimumAvailabilityDuration] = useState<number>(propsMinimumAvailabilityDuration);
  const [selectedAvailabilities, setSelectedAvailabilities] = useState<CustomEvent[]>([]);
  const isMobile = window.innerWidth < 1000;
  const today = useMemo(() => new Date(), []);
  const localToday = useMemo(() => utcToZonedTime(today, timezone), [timezone]);

  const [currentViewableDate, setCurrentViewableDate] = useState(() => {
    const tomorrow = addDays(localToday, 1);
    return {
      date: tomorrow,
      title: isMobile ?
        format(tomorrow, DateFormat.DateMonthYear, { locale: enUS }) :
        format(tomorrow, DateFormat.MonthYear, { locale: enUS }),
    };
  });

  const localMin = useMemo(() =>
    new Date(
      localToday.getFullYear(),
      localToday.getMonth(),
      localToday.getDate(),
      minHour
    )
    , [localToday, minHour]);

  const localMax = useMemo(() =>
    new Date(
      localToday.getFullYear(),
      localToday.getMonth(),
      localToday.getDate(),
      maxHour
    )
    , [localToday, maxHour]);

  useEffect(() => {
    document.dispatchEvent(new Event('availability_calendar_loaded'));
  }, []);

  const handleDateChange = useCallback((newDate: Date) => {
    if (newDate >= localToday && newDate < addYears(localToday, 1)) {
      setCurrentViewableDate({
        date: newDate,
        title: isMobile ?
          format(newDate, DateFormat.DateMonthYear, { locale: enUS }) :
          format(newDate, DateFormat.MonthYear, { locale: enUS }),
      })
    }
    cleanUpSubheaders();

  }, [setCurrentViewableDate, localToday]);

  // Helper function to clear out the tooltip after 4 seconds
  useEffect(() => {
    const availabilityRequiringAnnotation: CustomEvent = selectedAvailabilities.find((av: CustomEvent) => av.requiresAnnotation);
    if (availabilityRequiringAnnotation) {
      setTimeout(() => {
        setSelectedAvailabilities((prev: CustomEvent[]) => {
          availabilityRequiringAnnotation.requiresAnnotation = false;
          return [...prev];
        });
      }, 4000);
    }
  }, [selectedAvailabilities]);

  useEffect(() => {
    if (existingAvailabilities) {
      setSelectedAvailabilities(existingAvailabilities.map(existingAvailability => ({
        start: utcToZonedTime(fromUnixTime(existingAvailability.start), timezone),
        end: utcToZonedTime(fromUnixTime(existingAvailability.end), timezone),
      })));
    }
  }, [existingAvailabilities])

  // This function helps merge availabilities in the context where a new event is placed perfectly between two existing availabilities, allowing to merge them
  const mergeOverlapingAvailabilities = (existingAvailability: CustomEvent) => (availability: CustomEvent) => {
    if (existingAvailability.start === availability.start && existingAvailability.end === availability.end) {
      return true;
    }
    if (availability.end.getTime() === existingAvailability.start.getTime()) {
      existingAvailability.start = availability.start;
      return false;
    } else if (availability.start.getTime() <= existingAvailability.end.getTime() && availability.start.getTime() >= existingAvailability.start.getTime()) {
      existingAvailability.end = availability.end;
      return false;
    }
    return true;
  }

  const getNewAvailabilities = useCallback((prev: CustomEvent[], eventInfo: SlotInfo, newAvailability: CustomEvent) => {
    // Handle selection of entire day
    if (!eventInfo.box && !eventInfo.bounds) {
      newAvailability.start.setHours(minHour, 0);
      newAvailability.end.setHours(maxHour, 0);
      newAvailability.end = subDays(newAvailability.end, 1);
      prev = prev.filter(event => endOfDay(event.start).getTime() !== endOfDay(newAvailability.start).getTime());
      return [...prev, newAvailability];
    }

    const newAvailabilityDuration = differenceInMinutes(newAvailability.end, newAvailability.start);
    let requiresAnnotation = false;

    // If the new timeslot is too short, adjust it so that it respects the mininum time duration
    if (newAvailabilityDuration < minimumAvailabilityDuration) {
      newAvailability.end = addMinutes(newAvailability.start, minimumAvailabilityDuration);
      requiresAnnotation = true;

      // This is an edge case but if the user selects 4:45PM, we don't want to overflow into 5:xxPM, so we re-ajust the time to fit within the bounds of localMin hour and localMax hour
      const timeslotOverflow = differenceInMinutes(newAvailability.end, new Date(newAvailability.end).setHours(maxHour, 0))
      if (timeslotOverflow > 0) {
        newAvailability.end = subMinutes(newAvailability.end, timeslotOverflow);
        newAvailability.start = subMinutes(newAvailability.start, timeslotOverflow);
      }
    }
    // For each existing availability, verify if they overlap with the new created event, and merge them if needed
    for (let i = 0; i < prev.length; i++) {
      const existingAvailability = prev[i];
      if (newAvailability.end >= existingAvailability.start && newAvailability.start <= existingAvailability.end && newAvailability.start >= existingAvailability.start) {
        existingAvailability.end = new Date(Math.max(existingAvailability.end.getTime(), newAvailability.end.getTime()));
        existingAvailability.start = new Date(Math.min(existingAvailability.start.getTime(), newAvailability.start.getTime()));
        prev = prev.filter(mergeOverlapingAvailabilities(existingAvailability));
        return [...prev];
      } else if (newAvailability.start <= existingAvailability.end && newAvailability.end >= existingAvailability.start && newAvailability.start <= existingAvailability.start) {
        existingAvailability.start = new Date(Math.min(existingAvailability.start.getTime(), newAvailability.start.getTime()));
        existingAvailability.end = new Date(Math.max(existingAvailability.end.getTime(), newAvailability.end.getTime()));
        prev = prev.filter(mergeOverlapingAvailabilities(existingAvailability));
        return [...prev];
      }
    }

    // Add tooltip for 5 seconds to indicate that the time slot must be a minimum duration
    if (requiresAnnotation) {
      prev.forEach((av: CustomEvent) => av.requiresAnnotation = false);
      newAvailability.requiresAnnotation = true;
      newAvailability.minimumAvailabilityDuration = minimumAvailabilityDuration;
    }
    return [...prev, newAvailability];
  }, [minimumAvailabilityDuration]);

  const handleNewEvent = useCallback(
    (eventInfo: SlotInfo) => {
      if (readOnly) {
        return;
      }
      let newAvailability: CustomEvent = {
        start: new Date(eventInfo.start),
        end: new Date(eventInfo.end),
      };
      if (newAvailability.start < localToday
        || (newAvailability.start.getDate() === localToday.getDate()
          && newAvailability.start.getMonth() === localToday.getMonth()
          && newAvailability.start.getFullYear() === localToday.getFullYear()
          && addMinutes(newAvailability.start, minimumAvailabilityDuration).getTime() > localMax.getTime())
        || isBlockedDate(newAvailability.start)
        || newAvailability.start >= addDays(parseISO(inspectionSchedulingDeadline), 1)
      ) {
        return;
      }
      let prev = [...selectedAvailabilities];

      if (eventInfo.end.getDate() - eventInfo.start.getDate() > 1) {
        const newAvailabilities = [];
        for (let i = 0; i < eventInfo.end.getDate() - eventInfo.start.getDate(); i++) {
          const newFullDayAvailability = {
            start: new Date(newAvailability.start),
            end: new Date(newAvailability.end)
          };
          newFullDayAvailability.start.setHours(minHour, 0);
          newFullDayAvailability.start = addDays(newFullDayAvailability.start, i);
          newFullDayAvailability.end.setDate(newFullDayAvailability.start.getDate());
          newFullDayAvailability.end.setHours(maxHour, 0);
          prev = prev.filter(event => event.start.getDate() !== newFullDayAvailability.start.getDate());
          newAvailabilities.push(newFullDayAvailability);
        }

        setSelectedAvailabilities([...prev, ...newAvailabilities]);
      } else {
        setSelectedAvailabilities(getNewAvailabilities(prev, eventInfo, newAvailability));
      }
    },
    [selectedAvailabilities, setSelectedAvailabilities, localToday, localMax, inspectionSchedulingDeadline, minimumAvailabilityDuration]
  );

  // Helper function to update the selected availabilities
  const refreshAvailabilities = useCallback((newAvailability: CustomEvent, availabilitiesToFilter: CustomEvent[]) => {
    const filteredAvailabilities = selectedAvailabilities.filter((av) =>
      !availabilitiesToFilter.find(avToFilter =>
        avToFilter.end.getTime() === av.end.getTime() && avToFilter.start.getTime() === av.start.getTime()));

    setSelectedAvailabilities([...filteredAvailabilities, newAvailability]);
  }, [selectedAvailabilities]);

  const handleEventUpdate = useCallback(({
    start,
    end,
    event,
    isAllDay,
  }: {
    start: Date,
    end: Date,
    event: CustomEvent,
    isAllDay: boolean,
  }) => {
    if (readOnly) {
      return;
    }
    const oldAvailability = selectedAvailabilities.find((availability) =>
      availability.start.getTime() === event.start.getTime() && availability.end.getTime() === event.end.getTime());

    const newAvailability = {
      ...oldAvailability,
      start,
      end,
    }

    if (newAvailability.start < localToday
      || (newAvailability.start.getDate() === localToday.getDate()
        && newAvailability.start.getMonth() === localToday.getMonth()
        && newAvailability.start.getFullYear() === localToday.getFullYear()
        && addMinutes(newAvailability.start, minimumAvailabilityDuration).getTime() > localMax.getTime())
      || isBlockedDate(newAvailability.start)
      || newAvailability.start >= addDays(parseISO(inspectionSchedulingDeadline), 1)
    ) {
      return;
    }

    // Handle dragging zone outside of localMin to localMax range
    let maxTime = new Date(newAvailability.end)
    maxTime.setHours(maxHour, 0);

    let newDuration = differenceInMinutes(newAvailability.end, newAvailability.start);

    // The calendar can drag an event outside of the box so we need to make sure the time doesn't exceed the treshold
    if (maxTime < newAvailability.end) {
      newAvailability.end = maxTime;
      newDuration = differenceInMinutes(newAvailability.end, newAvailability.start);
      if (newDuration < minimumAvailabilityDuration) {
        newAvailability.start = subMinutes(newAvailability.end, minimumAvailabilityDuration);
      }
    }

    if (localToday > newAvailability.start) {
      newAvailability.start = roundToNearestMinutes(localToday, { nearestTo: 15, roundingMethod: 'ceil' });
      newDuration = differenceInMinutes(newAvailability.end, newAvailability.start);
      if (newDuration < minimumAvailabilityDuration) {
        newAvailability.end = addMinutes(start, minimumAvailabilityDuration);
      }
    }

    // When dragging a date into the day box, convert it into a localMin to localMax slot
    if (isAllDay && localToday.toDateString() !== newAvailability.start.toDateString()) {
      newAvailability.start.setHours(minHour, 0);
      newAvailability.end.setHours(maxHour, 0);
    }

    // Handle resizing an availability below the minimum treshold
    if (newDuration < minimumAvailabilityDuration) {
      if (oldAvailability.start.getTime() < newAvailability.start.getTime()) {
        newAvailability.start = subMinutes(oldAvailability.end, minimumAvailabilityDuration);
      } else {
        newAvailability.end = addMinutes(oldAvailability.start, minimumAvailabilityDuration);
      }
      selectedAvailabilities.forEach((av: CustomEvent) => av.requiresAnnotation = false);
      oldAvailability.requiresAnnotation = true;
      oldAvailability.minimumAvailabilityDuration = minimumAvailabilityDuration;
    }

    const availabilitiesToFilter = [oldAvailability];

    // Handle overlapping availabilities and merge them
    selectedAvailabilities.forEach((existingAvailability) => {
      if (existingAvailability === oldAvailability) {
        return;
      }

      if (existingAvailability.end.getTime() >= newAvailability.start.getTime()) {
        if (existingAvailability.start.getTime() < newAvailability.start.getTime()) {
          newAvailability.start = existingAvailability.start;
          availabilitiesToFilter.push(existingAvailability);
        } else if (existingAvailability.start.getTime() >= newAvailability.start.getTime() && existingAvailability.start.getTime() <= newAvailability.end.getTime()) {
          if (existingAvailability.end.getTime() > newAvailability.end.getTime()) {
            newAvailability.end = existingAvailability.end;
          }
          availabilitiesToFilter.push(existingAvailability);
        }
      }

      if (existingAvailability.start.getTime() <= newAvailability.end.getTime()) {
        if (existingAvailability.end.getTime() > newAvailability.end.getTime()) {
          newAvailability.end = existingAvailability.end;
          availabilitiesToFilter.push(existingAvailability);
        } else if (existingAvailability.end.getTime() <= newAvailability.end.getTime() && existingAvailability.end.getTime() >= newAvailability.start.getTime()) {
          if (existingAvailability.start.getTime() < newAvailability.start.getTime()) {
            newAvailability.start = existingAvailability.start;
          }
          availabilitiesToFilter.push(existingAvailability);
        }
      }
    });

    refreshAvailabilities(newAvailability, availabilitiesToFilter);
  }, [localToday, selectedAvailabilities, minimumAvailabilityDuration, maxHour, minHour, inspectionSchedulingDeadline])

  const handleDeleteAvailability = useCallback((availability: CustomEvent) => {
    if (readOnly) {
      return;
    }
    setSelectedAvailabilities((prev) => {
      prev = prev.filter(av => av !== availability);
      return prev;
    })
  }, [setSelectedAvailabilities]);

  const isBlockedDate = useCallback((date: Date) => !!blockedDates.find(blockedDate => {
    const blockedDateObj = new Date(blockedDate);
    return blockedDateObj.getDate() === date.getDate() && blockedDateObj.getMonth() === date.getMonth() && blockedDateObj.getFullYear() === date.getFullYear()
  }), [blockedDates]);

  const getProperties = useCallback((date) => {
    if (isBlockedDate(date) || date >= addDays(parseISO(inspectionSchedulingDeadline), 1)) {
      return { className: 'blocked' };
    } else {
      return {};
    }
  }, [isBlockedDate, inspectionSchedulingDeadline]);

  // Helper function to determine if the user has met requirements to progress past the calendar page
  const checkAvailabilityRequirementsMet = () => {
    const earliestEligibleDate = getEarliestEligibleDate(localToday, inspectionSchedulingDeadline);

    let count = 0;
    for (let i = 0; i < selectedAvailabilities.length; i++) {
      if (selectedAvailabilities[i].start > earliestEligibleDate) {
        const availabilityDuration = differenceInMinutes(selectedAvailabilities[i].end, selectedAvailabilities[i].start);
        count += Math.floor(availabilityDuration / minimumAvailabilityDuration);
      }
      if (count >= 2) return true;
    }
    return false;
  };

  const blockedDateIndices = useMemo(() => {
    let indices: number[] = [];
    if (isMobile) {
      if (isBlockedDate(currentViewableDate.date)) indices.push(0);
    } else {
      const sunday = startOfWeek(new Date(currentViewableDate.date));
      for (let i = 0; i < 7; i++) {
        const dayOfWeek: Date = addDays(sunday, i);
        if (isBlockedDate(dayOfWeek)) indices.push(i);
      }
    }
    return indices;
  }, [currentViewableDate]);

  useEffect(() => {
    let subheaderDivs = document.querySelectorAll('.rbc-day-bg');
    subheaderDivs.forEach((subheaderDiv, index) => {
      if (blockedDateIndices.includes(index)) {
        const actualDate = addDays(startOfWeek(currentViewableDate.date), index);
        if (actualDate.getDate() === 1 && actualDate.getMonth() === 7 && actualDate.getFullYear() === 2024) {
          subheaderDiv.textContent = "Inspectify Team Training";
        } else {
          subheaderDiv.textContent = "Cannot book on a holiday";
        }
      }
    });
  }, [blockedDateIndices]);

  const cleanUpSubheaders = () => {
    let subheaderDivs = document.querySelectorAll('.rbc-day-bg');
    subheaderDivs.forEach((subheaderDiv) => {
      subheaderDiv.textContent="";
    });
  };

  const getEventProperties = (event: CustomEvent) => {
    if (isDateEligible(localToday, event.start, inspectionSchedulingDeadline)) {
      return { className: 'eligible' };
    } else {
      return { className: 'ineligible' };
    }
  }

  const availabilityRequirementsMet = useMemo(() => (
    checkAvailabilityRequirementsMet()
  ), [selectedAvailabilities, inspectionSchedulingDeadline]);

  useEffect(() => {
    if (availabilityRequirementsMet) {
      var event = new Event("availability_requirements_met");
      document.dispatchEvent(event);
    } else {
      var event = new Event("availability_requirements_not_met");
      document.dispatchEvent(event);
    }
  }, [availabilityRequirementsMet]);

  document.addEventListener('contingency_date_changed', (e: any) => {
    setInspectionSchedulingDeadline(e.detail);
  });

  document.addEventListener('order_availabilities_changed', (e: any) => {
    setSelectedAvailabilities(e.detail.map(existingAvailability => ({
      start: utcToZonedTime(fromUnixTime(existingAvailability.start), timezone),
      end: utcToZonedTime(fromUnixTime(existingAvailability.end), timezone),
    })));
  });

  document.addEventListener('minimum_availability_duration_changed', (e: any) => {
    setMinimumAvailabilityDuration(e.detail);
  });

  return (
    <AvailabilityCalendar
      selectedAvailabilities={selectedAvailabilities}
      currentViewableDate={currentViewableDate}
      min={localMin}
      max={localMax}
      isMobile={isMobile}
      minimumAvailabilityDuration={minimumAvailabilityDuration}
      inspectionSchedulingDeadline={inspectionSchedulingDeadline}
      timezone={timezone}
      readOnly={readOnly}
      minimized={minimized}
      vertical={vertical}
      panelOnly={panelOnly}
      localToday={localToday}
      getProperties={getProperties}
      getEventProperties={getEventProperties}
      handleSelectSlot={handleNewEvent}
      handleDateChange={handleDateChange}
      handleEventUpdate={handleEventUpdate}
      handleDeleteAvailability={handleDeleteAvailability}
      getNow={() => localToday}
    />
  );
}

export default AvailabilityCalendarLoader;
