import { addDays, addWeeks, subDays, subWeeks } from "date-fns";
import React from "react";
import IconButton from "./IconButton";
import ChevronLeft from '../../../assets/images/chevron-left.svg';
import ChevronRight from '../../../assets/images/chevron-right.svg';

interface Props {
  /**
   * The date representing the date/week to display on the calendar
   */
  currentViewableDate: {
    date: Date,
    title: string,
  },
   /**
   * Displays the calendar in mobile mode
   */
  isMobile: boolean,
  /**
   * Changes the selected date to display on the calendar
   * @param newDate The new week date to display
   * @returns 
   */
  handleDateChange: (newDate: Date) => void,
}

const DateNavigation: React.FC<Props> = ({
  currentViewableDate,
  isMobile,
  handleDateChange,
}) => (
  <div className='dateNavigation'>
    <div className="monthText">
      {currentViewableDate.title}
    </div>
      <IconButton onClick={() => handleDateChange(isMobile ? subDays(currentViewableDate.date, 1) : subWeeks(currentViewableDate.date, 1))}>
        <ChevronLeft className="mx-3" />
      </IconButton>
      <IconButton onClick={() => handleDateChange(isMobile ? addDays(currentViewableDate.date, 1) : addWeeks(currentViewableDate.date, 1))}>
        <ChevronRight className="mx-3" />
      </IconButton>
  </div>
)

export default DateNavigation;