import React, {useMemo, useState} from "react";
import './styles.scss';
import {APIProvider, Map, Marker} from "@vis.gl/react-google-maps";
import OrderMarker from "./OrderMarker";
import {Order} from "./types";

interface Props {
    googleMapApiKey: string,
    mapId: string,
    orders: Order[],
}

const BundleBuilderMap: React.FC<Props> = ({googleMapApiKey, mapId, orders}) => {
    const [bundleOrderIds, setBundleOrderIds] = useState<Set<number>>(new Set());
    const [submittedOrderIds, setSubmittedOrderIds] = useState<Set<number>>(new Set());
    const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null);

    const { centerLatitude, centerLongitude } = useMemo(() => {
        const centerLatitude = orders.reduce((acc, order) => acc + parseFloat(order.latitude), 0) / orders.length;
        const centerLongitude = orders.reduce((acc, order) => acc + parseFloat(order.longitude), 0) / orders.length;
        return { centerLatitude, centerLongitude };
    }, [orders]);

    const addOrderToBundle = (order: Order) => {
        const newSet = new Set(bundleOrderIds);
        newSet.add(order.id);
        setBundleOrderIds(newSet);
        setSelectedOrderId(null)
    }

    const removeOrderFromBundle = (order: Order) => {
        const newSet = new Set(bundleOrderIds);
        newSet.delete(order.id);
        setBundleOrderIds(newSet);
        setSelectedOrderId(null);
    }

    const handleSubmit = (e: any) => {
      setSubmittedOrderIds(new Set([...submittedOrderIds, ...bundleOrderIds]));
      setBundleOrderIds(new Set());
      e.target.form.submit();
    }

    return (
      <APIProvider apiKey={googleMapApiKey}>
          <Map
            style={{width: '100%', height: '600px'}}
            defaultCenter={{lat: centerLatitude, lng: centerLongitude}}
            defaultZoom={5}
            gestureHandling={'greedy'}
            disableDefaultUI={true}
            mapId={mapId}
            onClick={() => setSelectedOrderId(null)}
          >
              {orders.map(order => (
                <OrderMarker key={order.id}
                             infoWindowShown={selectedOrderId === order.id}
                             onClick={() => setSelectedOrderId(order.id)}
                             onClose={() => setSelectedOrderId(null)}
                             order={order}
                             isOnBundle={bundleOrderIds.has(order.id)}
                             isAlreadySubmitted={submittedOrderIds.has(order.id)}
                             addOrderToBundle={addOrderToBundle}
                             removeOrderFromBundle={removeOrderFromBundle}/>
              ))}
          </Map>
          <input hidden value={Array.from(bundleOrderIds).join(',')} name="order_ids"/>
          <button type="button" onClick={handleSubmit} className="btn btn-primary" disabled={bundleOrderIds.size === 0}>
              Submit
          </button>
      </APIProvider>
    )
};

export default BundleBuilderMap;
